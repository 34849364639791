<template>
    <div class="day-trend-big-box">
        <div class="day-trend-box">
            <h1 class="day-trend-head">
                <span></span>
                <span>日变化趋势图</span>
                <span class="iconfont" @click="closeTrend">&#xe67d;</span>
            </h1>
            <div class="day-trend-body">
                <div class="body-echarts">
                    <div class="body-head">
                        <span @click="clickChange(1)" :class="checkEcharts === 1 ? 'span-select' : ''">砼方量</span>
                        <span @click="clickChange(2)" :class="checkEcharts === 2 ? 'span-select' : ''">砼金额</span>
                    </div>
                    <div style="width:100%;height:280px;" id="dayEcharts"></div>
                </div>
                <div class="table-list">
                    <el-table
                        :data="tabListDataName"
                        show-summary
                        :summary-method="getSummaries"
                        max-height="300"
                    >
                        <el-table-column
                            prop="name"
                            label="搅拌站"
                            width="150"
                            fixed
                        >
                        </el-table-column>
                        <el-table-column
                            v-for="(date, index) in tabListDataDate"
                            :key="index"
                            :label="date"
                            width="130"
                        >
                            <el-table-column
                                label="日方量(方)"
                                width="130"
                                :prop="date"
                            >
                                <template slot-scope="scope">
                                    <span v-for="(key, idx) in scope.row.list" :key="idx">
                                        <template v-if="key.date === date">
                                            {{ key.fl }}
                                        </template>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="日金额(元)"
                                width="130"
                                :prop="date"
                            >
                                <template slot-scope="scope">
                                    <span v-for="(key, idx) in scope.row.list" :key="idx">
                                        <template v-if="key.date === date">
                                            {{ key.je }}
                                        </template>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="日均价(元/方)"
                                width="130"
                                :prop="date"
                            >
                                <template slot-scope="scope">
                                    <span v-for="(key, idx) in scope.row.list" :key="idx">
                                        <template v-if="key.date === date">
                                            {{ key.jj }}
                                        </template>
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import addBgImages from '../../utils';
export default {
    data() {
        return {
            tabListDataList: [],
            tabListDataName: [],
            tabListDataDate: [],
            tabListEchartsHt: [],
            legendData: [],
            echartsColor: ['#EA8343', '#3E79C4', '#E6E429', '#FA9E94'],
            checkEcharts: 1,
            fangliangTotal: {},
        };
    },
    props: {
        timeData: {
            type: [String],
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.salesanalysisDay();
        });
    },
    methods: {
        clickChange(data) {
            this.checkEcharts = data;
            this.salesanalysisDay();
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                // 获取与当前行时间相同的数据
                const arr = this.tabListDataList.filter(item => {
                    return column.property === item.date;
                });
                // 对应时间合同数量和预计销售方量的数值数组 // 对应每一列的数据数组
                const values = arr.map(item => {
                    if (column.label === '日方量(方)') {return item.fl;}
                    if (column.label === '日金额(元)') {return item.je;}
                    // if (column.label === '日均价') {return item.jj;}
                });
                // const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        }
                        return prev;

                    }, 0);
                } else {
                    sums[index] = ' ';
                    if (column.label === '日均价(元/方)') {
                        let total = 0;
                        let totalXl = 0;
                        arr.forEach(item => {
                            total += item.je * 100;
                            totalXl += Math.round(item.fl * 100);
                        });
                        sums[index] = Number(total) ? (total / totalXl).toFixed(2) : '0.00';
                    }
                }
            });
            return sums;
        },
        closeTrend() {
            this.$emit('closeTrend');
        },
        dayEchartsFun(data) {
            const myChart = this.$echarts.init(document.getElementById('dayEcharts'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    data: this.legendData,
                    itemWidth: 10,
                    itemHeight: 10,
                    padding: [10, 0, 0, 0],
                    selected: {
                        合计日方量: false,
                        合计日金额: false,
                    },
                },
                grid: {
                    top: '25%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListDataDate,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: this.checkEcharts === 1 ? '单位(方)' : '单位(元)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        splitLine: { show: false },
                    },
                    {
                        type: 'value',
                        name: '单位(元/方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        splitLine: { show: false },
                    },
                ],
                series: data,
            };
            myChart.setOption(option, true);
        },
        salesanalysisDay() {
            this.$axios
                .get(`/interfaceApi/report/salesanalysis/xsqk/cz/days?date=${this.timeData}&datenum=7`)
                .then(res => {
                    this.tabListDataList = res.list;
                    this.tabListDataDate = res.date;
                    this.tabListEchartsHt = res.name;
                    this.tabListEchartsHt.push(' ');
                    this.fangliangTotal = {};
                    // 表格渲染
                    this.tabListDataName = res.name.map(item => {
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        return {
                            name: item,
                            list: arr,
                        };
                    });
                    // 分组名称
                    const getSeriesData = res.name.map(item => {
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        return arr;
                    });

                    let htEchartSeriesData = [];
                    if (this.checkEcharts === 1) {
                        // 日方量
                        htEchartSeriesData = getSeriesData.map((item, index) => {
                            let htItemListData = [];
                            htItemListData = item.map(info => {
                                return info.fl ? info.fl : ',';
                            });
                            const arrName = this.tabListEchartsHt[index];
                            const arrColor = this.echartsColor[index];
                            const htEchartSeries = {
                                name: arrName + '日方量',
                                type: 'bar',
                                data: htItemListData,
                                label: {
                                    fontFamily: 'QuartzLTStd',
                                    show: true,
                                    position: 'top',
                                    color: arrColor,
                                    fontSize: '14',
                                    padding: 5,
                                // backgroundColor: {
                                //     image: addBgImages(index, 'top'),
                                // },
                                },
                                itemStyle: {
                                    color: arrColor,
                                },
                            };
                            return htEchartSeries;
                        });
                        // 合计日方量
                        const totalFangLiang = res.date.map(item => {
                            const arr = res.list.filter(key => {
                                return key.date === item;
                            });
                            let total = 0;
                            arr.forEach(a => {
                                total = Math.round(total * 100 + a.fl * 100) / 100;
                            });
                            return total ? total : ',';
                        });
                        this.fangliangTotal = {
                            name: '合计日方量',
                            type: 'bar',
                            data: totalFangLiang,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: '#EA5D5D',
                                fontSize: '14',
                                padding: 5,
                            // backgroundColor: {
                            //     image: addBgImages(index, 'top'),
                            // },
                            },
                            itemStyle: {
                                color: '#EA5D5D',
                            },
                        };
                    } else if (this.checkEcharts === 2) {
                        // 日金额
                        htEchartSeriesData = getSeriesData.map((item, index) => {
                            let htItemListData = [];
                            htItemListData = item.map(info => {
                                return info.je ? info.je : ',';
                            });
                            const arrName = this.tabListEchartsHt[index];
                            const arrColor = this.echartsColor[index];
                            const htEchartSeries = {
                                name: arrName + '日金额',
                                type: 'bar',
                                data: htItemListData,
                                label: {
                                    fontFamily: 'QuartzLTStd',
                                    show: true,
                                    position: 'top',
                                    color: arrColor,
                                    fontSize: '14',
                                    padding: 5,
                                // backgroundColor: {
                                //     image: addBgImages(index, 'top'),
                                // },
                                },
                                itemStyle: {
                                    color: arrColor,
                                },
                            };
                            return htEchartSeries;
                        });
                        // 合计日金额
                        const totalFangLiang = res.date.map(item => {
                            const arr = res.list.filter(key => {
                                return key.date === item;
                            });
                            let total = 0;
                            arr.forEach(a => {
                                total = Math.round(total * 100 + a.je * 100) / 100;
                            });
                            return total ? total : ',';
                        });
                        this.fangliangTotal = {
                            name: '合计日金额',
                            type: 'bar',
                            data: totalFangLiang,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: '#EA5D5D',
                                fontSize: '14',
                                padding: 5,
                            // backgroundColor: {
                            //     image: addBgImages(index, 'top'),
                            // },
                            },
                            itemStyle: {
                                color: '#EA5D5D',
                            },
                        };
                    }

                    // 日均价合计
                    const junjiaTotalData = res.date.map(item => {
                        const arr = res.list.filter(key => {
                            return key.date === item;
                        });
                        let jj = 0;
                        let je = 0;
                        let fl = 0;
                        arr.forEach(a => {
                            if (a.je) {
                                je = Math.round(je * 100 + a.je * 100) / 100;
                            }
                            if (a.fl) {
                                fl = Math.round(fl * 100 + a.fl * 100) / 100;
                            }
                        });
                        if (fl !== 0) {
                            jj = (je / fl).toFixed(2);
                        }
                        return jj;
                    });
                    let junjiaTotal = {};
                    junjiaTotal = {
                        name: '日均价',
                        type: 'line',
                        yAxisIndex: 1,
                        data: junjiaTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '14',
                            padding: 5,
                            // backgroundColor: {
                            //     image: addBgImages(11, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: '#EA5D5D',
                            },
                        },
                    };
                    let newflList = [];
                    this.legendData = [];
                    newflList = htEchartSeriesData.slice(0, -1);
                    newflList.push(this.fangliangTotal, junjiaTotal);
                    newflList.forEach(item => {
                        this.legendData.push(item.name);
                    });
                    this.dayEchartsFun(newflList);
                })
                .catch(err => {
                    this.$message.error(err.ErrorCode.message);
                });
        },
    },
};

</script>
<style lang='stylus'>
.day-trend-big-box
    height 100%
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0,0,0,0.4)
    z-index 99
    display flex
    justify-content center
    align-items center
    .el-table__body tbody .el-table__row:last-child
        display none
    .day-trend-box
        background #fff
        width 90%
        height 85%
        .day-trend-head
            width 100%
            background #0C2C87
            text-align center
            color #fff
            padding .1rem 0;
            display flex
            justify-content space-between
            align-items center
            span
                font-size .2rem
                &:last-child
                    margin-right .2rem
                    cursor pointer
        .day-trend-body
            width 100%
            padding .2rem
            .body-echarts
                border 1px solid #D7D7D7
                margin-bottom .2rem
                position relative
                .body-head
                    position absolute
                    right .2rem
                    top 2%
                    color #666666
                    font-size .14rem
                    border 1px solid #D7D7D7
                    border-radius .04rem
                    height .32rem
                    display flex
                    z-index 99
                    span
                        display block
                        cursor pointer
                        width .6rem
                        height .3rem
                        text-align center
                        background #f6f8fc
                        line-height .28rem
                        cursor pointer
                        // border-bottom 1px solid #D7D7D7
                        &:first-child
                            border-radius .04rem 0 0 .04rem
                        &:last-child
                            border-radius 0 .04rem .04rem 0
                    .span-select
                        background #1577D2
                        color #fff
            .table-list
                margin-top .2rem
                width 100%
                padding-bottom .2rem
                .el-table
                    .el-table__header
                        border-collapse: collapse;
                    thead tr th
                        color #022782
                        font-size .16rem
                        text-align center
                        padding 0
                    tbody tr td
                        height .4rem
                        text-align center
                        padding 0
                        font-size .14rem
            .el-table__fixed-footer-wrapper table tbody tr
                td
                    background none
                    color #C9661E
                    &:first-child
                        background #F5F2ED
                        color #823802
                        font-size .16rem
            .el-table__footer-wrapper table tbody td
                background none
                color #C9661E
</style>